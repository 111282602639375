/**
 * A Table Footer
 */
import styled from 'styled-components';

const Tfoot = styled.tfoot`
`;

/** @component */
export default Tfoot;
