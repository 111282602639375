/**
 * Tbody
 */

import styled from 'styled-components';

const Tbody = styled.tbody`
`;

/** @component */
export default Tbody;
