/**
 * Samp component
 */


import styled from 'styled-components';

const Samp = styled.samp`
  font-family: monospace;
  font-size: 1em;
`;

/** @component */
export default Samp;
